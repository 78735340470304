const initialize = () => {
  const toggleButton = document.querySelector('#toggle-menu')
  const siteHeader = document.querySelector('#site-header')

  let prevScrollpos = window.pageYOffset

  window.onscroll = () => {
    const currentScrollPos = window.pageYOffset

    if (prevScrollpos > currentScrollPos) {
      siteHeader.style.top = '0'
    } else {
      siteHeader.style.top = `${
        -1 * siteHeader.getBoundingClientRect().height
      }px`
    }

    prevScrollpos = currentScrollPos
  }

  toggleButton.addEventListener('click', e => {
    e.preventDefault()
    siteHeader.dataset.showNav =
      siteHeader.dataset.showNav == 'false' ? 'true' : 'false'
  })
}

window.addEventListener('turbolinks:load', initialize)
